const API_BASE_URL = "https://artfrocollection.com/php/artadmin";

const API_ENDPOINTS = {
    LOGIN: `${API_BASE_URL}/adminLogin.php`,
    SUBSCRIBERS: `${API_BASE_URL}/getData.php?api=subscribers`,
    DONATIONS: `${API_BASE_URL}/getData.php?api=donations`,
    REG_USERS: `${API_BASE_URL}/getData.php?api=users`,
    PRODUCTS: `${API_BASE_URL}/getData.php?api=products`,
    PRODUCT_CATEGORIES: `${API_BASE_URL}/getData.php?api=productCategories`,
    ARTISTS: `${API_BASE_URL}/getData.php?api=artists`,
    ENQURIES: `${API_BASE_URL}/getData.php?api=enquiries`,
    ORDERS: `${API_BASE_URL}/getData.php?api=orders`,
    CATEGORIES_UPDATE: `${API_BASE_URL}/updateData.php?api=updateCategories`,
    CATEGORIES_ADDING: `${API_BASE_URL}/addData.php?api=productCategories`,
    CATEGORIES_DELETING: `${API_BASE_URL}/deleteData.php?api=productCategories`,
    UPDATE_ARTIST: `${API_BASE_URL}/updateData.php?api=updateEmployees`,
    ADD_ARTIST: `${API_BASE_URL}/addData.php?api=addEmployees`,
    DELETE_ARTIST: `${API_BASE_URL}/deleteData.php?api=deleteEmployees`,
    UPDATE_PRODUCT: `${API_BASE_URL}/updateData.php?api=updateProduct`,
    INSERT_PRODUCT: `${API_BASE_URL}/addData.php?api=insertProduct`,
    DELETE_PRODUCT: `${API_BASE_URL}/deleteData.php?api=deleteProduct`,


};

export default API_ENDPOINTS;
