import React from 'react';
import { Row, Col, Image, NavDropdown, Nav } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import './TopNavbar.css';
import { useAuth } from '../../context/AuthContext';
import Cookies from 'js-cookie';


function TopNavbar() {
    const { logout } = useAuth();
    const handleLogout = () => {
        logout();
    }
    const user = JSON.parse(Cookies.get('artfroAdminUser'));

    console.log("user",user);
    
    return (
        <div className='top-navbar'>
            <Nav>
                <NavDropdown
                    id="nav-dropdown"
                    title={
                        <Row className="nav-dropdown-profile-img">
                            <Col sm={4} className='d-flex justify-content-around align-items-center'>
                                <div>
                                    <Image
                                        src={require('../../assets/bussinessman.jpeg')}
                                        roundedCircle
                                        alt="profile-img"
                                        className='profile-img'
                                    />
                                </div>
                            </Col>
                            <Col sm={8}><div className='f-18 mt-2 mr-5 text-dark'>
                                <div>
                                    <span style={{ display: 'inline-block', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user.name}</span>
                                </div>
                            </div>
                            </Col>
                        </Row>
                    }
                >
                    <NavDropdown.Item eventKey="4.2">
                        <Row>
                            <NavLink to="/" className="text-dark f-16" activeclassname="active" onClick={handleLogout} style={{ textDecoration: 'none' }} >
                                <Col sm={12} className='d-flex'>
                                    <div><i className="fa-solid fa-right-from-bracket"></i></div> &nbsp;&nbsp;
                                    <div className='f-18'>Logout</div>
                                </Col>
                            </NavLink>
                        </Row>
                    </NavDropdown.Item>
                </NavDropdown>
            </Nav>
        </div>
    );
}

export default TopNavbar;
