import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!Cookies.get('artfroAdminToken'));
    const [loading, setLoading] = useState(true); 

    const login = (token, user) => {
        Cookies.set('artfroAdminToken', token);
        Cookies.set('artfroAdminUser', user);
        setIsAuthenticated(true);
        setLoading(false); 
    };

    const logout = () => {
        Cookies.remove('artfroAdminToken');
        Cookies.remove('artfroAdminUser');
        setIsAuthenticated(false);
        setLoading(false); 
    };

    React.useEffect(() => {
        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
