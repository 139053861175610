import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./fontawesome/css/all.min.css";
import './App.css';
// import Cookies from 'js-cookie';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import SideNavbar from './components/SideNavbar/SideNavbar';
import TopNavbar from './components/TopNavbar/TopNavbar';
import PageNotFound from './components/PageNotFound/PageNotFound';
import RegisteredUsers from './components/RegisteredUsers/RegisteredUsers';
// import AddCategories from './components/Categories/AddCategories';
import Categories from './components/Categories/Categories';
import Artists from './components/Artists/Artists';
import Products from './components/Products/Products';
import Orders from './components/Orders/Orders';
import Subscriptions from './components/Subscriptions/Subscriptions';
import ContactSupport from './components/ContactSupport/ContactSupport';
import Donations from './components/Donations/Donations';
import PrivateRoute from './context/PrivateRoute';

const routes = [
  { path: '/dashboard', name: 'Login', component: Dashboard, excludeFromSidebar: false },
  { path: '/registered-users', name: 'RegisteredUsers', component: RegisteredUsers, excludeFromSidebar: false },
  { path: '/categories', name: 'categories', component: Categories, excludeFromSidebar: false },
  { path: '/artists', name: 'artists', component: Artists, excludeFromSidebar: false },
  { path: '/products', name: 'products', component: Products, excludeFromSidebar: false },
  { path: '/orders', name: 'orders', component: Orders, excludeFromSidebar: false },
  { path: '/subscriptions', name: 'subscriptions', component: Subscriptions, excludeFromSidebar: false },
  { path: '/contact-support', name: 'contact', component: ContactSupport, excludeFromSidebar: false },
  { path: '/donations', name: 'contact', component: Donations, excludeFromSidebar: false },
]

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<PrivateRoute />}>
          {routes.map(({ path, name, component: Component, excludeFromSidebar }) => (
            <Route
              key={path}
              path={path}
              element={
                excludeFromSidebar ? (
                  <Component />
                ) : (
                  <div className='wrapper'>
                    <div className='side-wrapper'>
                      <SideNavbar />
                    </div>
                    <div className='content-wrapper'>
                      <div className='content-top-wrapper'>
                        <TopNavbar />
                      </div>
                      <div className='conten-main-wrapper'>
                        <Component />
                      </div>
                    </div>
                  </div>
                )
              }
            />
          ))}
        </Route>
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;


