import React, { useState } from 'react';
import { Container, Spinner, Button, Form, Row, Col } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { MaterialReactTable } from 'material-react-table';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import AddProductModal from './AddProductModal';
import EditProductModal from './EditProductModal';
import API_ENDPOINTS from '../../config/apiConfig';
import { fetchFromApi } from '../../config/api';

function Products() {
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [selectedArtist, setSelectedArtist] = useState("0");



    const { productsData, loading, error, refetch, artistData, fetchProductsData } = useData();
    const [deleteLoading, setDeleteLoading] = useState(false); // State for loading during deletion
    const [deleteError, setDeleteError] = useState(null); // State for delete error




    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
                <Button onClick={refetch}>Retry</Button>
            </div>
        );
    }

    if (!productsData) {
        return <div>No product data available.</div>;
    }

    const filteredProducts = selectedArtist === "0" ? productsData : productsData.filter(product => product.artist_id === parseInt(selectedArtist));

    const activeEditModal = (row) => {
        console.log("row111", row);

        setEditData(row.original);
        setEditModal(true);
    };

    const deleteRow = async (row) => {

        if (window.confirm("Are you sure you want to delete this product ?")) {
            setDeleteLoading(true); // Set loading to true while deleting
            try {
                const formBody = {
                    id: row.original.id,
                };

                const data = await fetchFromApi(API_ENDPOINTS.DELETE_PRODUCT, 'POST', formBody);
                console.log("dataa", data);

                if (data.status === "success") {
                    await fetchProductsData();
                }
            } catch (error) {
                console.error(error);
                setDeleteError("Failed to delete category. Please try again."); // Set error message
            } finally {
                setDeleteLoading(false); // Reset loading state
            }
        }
    };

    const renderRowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
                <IconButton onClick={() => activeEditModal(row)}>
                    <ModeEditOutlineSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton onClick={() => deleteRow(row)} disabled={deleteLoading}>
                    <DeleteIcon className='text-danger' />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'product_image',
            header: 'Product Image',
            Cell: ({ cell }) => (
                <img
                    src={cell.getValue()}
                    alt="Product"
                    style={{ width: '100px', height: '100px', borderRadius: '5px' }}
                />
            ),
        },
        { accessorKey: 'product_name', header: 'Product Name' },
        { accessorKey: 'product_description', header: 'Product Description' },
        { accessorKey: 'product_short_description', header: 'Product Short Description' },
        { accessorKey: 'image_dimensions', header: 'Image Dimensions' },
        {
            accessorKey: 'product_final_price',
            header: 'Price',
            Cell: ({ cell }) => `$${cell.getValue()}`,
        },
        {
            id: 'actions',
            header: 'Actions',
            Cell: (props) => renderRowActions(props),
            size: 100,
            muiTableBodyCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
        }
    ];

    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='main-heading-pages'>Products</p>
                </div>
                <div className='p-2'>
                    <Button type="button" variant="outline-success" className="f-14" onClick={() => setAddModal(true)}>
                        <i className="fa-solid fa-plus"></i> Add Product
                    </Button>
                </div>
            </div>



            {/* Products Table */}
            <Paper style={{ height: '450px', overflowY: 'auto', padding: 20 }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={3}>
                            <Form.Group controlId="artistFilter">
                                <Form.Label className="fw-bold mb-2">Filter by Artist</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedArtist}
                                    onChange={(e) => setSelectedArtist(e.target.value.toString())}
                                    aria-label="Select Artist"
                                >
                                    <option value="0">ALL</option>
                                    {artistData.map(artist => (
                                        <option key={artist.id} value={artist.id}>
                                            {artist.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={filteredProducts} // Using filtered products here
                    enableSorting
                    enablePagination
                />
            </Paper>

            {/* Add and Edit Modals */}
            {addModal && (
                <AddProductModal
                    show={addModal}
                    onHide={() => setAddModal(false)}
                />
            )}
            {editModal && (
                <EditProductModal
                    show={editModal}
                    editData={editData}
                    onHide={() => {
                        setEditModal(false);
                        setEditData({});
                    }}
                />
            )}
            {deleteError && <div className="text-danger">{deleteError}</div>}

        </Container>
    );
}

export default Products;
