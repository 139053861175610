import React from 'react';
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import Loading from '../Loading/Loading';
import './Dashboard.css'

function Dashboard() {

    const navigate = useNavigate();

    const { userData, orderData, donationsData, subscribersData, todaySubscriptions, contactedData, productsData, loading, error, todayRegistrations, totalDonationsAmount, todayContacted } = useData();

    if (loading) {
        return <Loading />
    }
    if (error) return <p>Error: {error}</p>;

    const handleNaviagekFunc = (navigateType) => {
        if (navigateType === "users") {
            navigate('/registered-users');
        } else if (navigateType === "orders") {
            navigate('/orders');
        } else if (navigateType === "donations") {
            navigate('/donations');
        } else if (navigateType === "subscriptions") {
            navigate('/subscriptions')
        }
        else if (navigateType === "contact-support") {
            navigate('/contact-support')
        }
        else if (navigateType === "products") {
            navigate('/products')
        }
    };

    return (
        <Container>
            <div className='pt-4'>
                <Row>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={10}>
                                        <h4 className="f-21 text-uppercase fw-bold">Registered Users</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Registered : <span className='count'>{userData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Today Registered : <span className='count'>{todayRegistrations.length}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={2}>
                                        <i class="fa-regular fa-user float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14  mt-2" onClick={() => handleNaviagekFunc("users")} >
                                    View Users
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <h4 className="f-21 text-uppercase fw-bold">Orders</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Orders : <span className='count'>{orderData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Total Orders Amount : <span className='count'>{orderData.length}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <i class="fa fa-shopping-cart float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14 mt-2" onClick={() => handleNaviagekFunc("orders")} >
                                    View Orders
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={9}>
                                        <h4 className="f-21 text-uppercase fw-bold">Donations</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Donations : <span className='count'>{donationsData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Total Donations Amount : <span className='count'>${totalDonationsAmount}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={3}>
                                        <i class="fa-solid fa-hand-holding-dollar float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14 mt-2" onClick={() => handleNaviagekFunc("donations")} >
                                    View  Users
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <h4 className="f-21 text-uppercase fw-bold">Subscriptions</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Subscribers : <span className='count'>{subscribersData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Today Subscribers : <span className='count'>{todaySubscriptions.length}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <i class="fa-regular fa-bell float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14 mt-2" onClick={() => handleNaviagekFunc("subscriptions")} >
                                    View Subscribers
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <h4 className="f-21 text-uppercase fw-bold">Contact Support</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Contact : <span className='count'>{contactedData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Today Contact : <span className='count'>{todayContacted.length}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <i class="fa-solid fa-headset float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14 mt-2" onClick={() => handleNaviagekFunc("contact-support")} >
                                    View Contact Support
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={4}>
                        <Card className="">
                            <Card.Body>
                                <Row>
                                    <Col sm={8}>
                                        <h4 className="f-21 text-uppercase fw-bold">Products</h4>
                                        <div>
                                            <Row>
                                                <Col sm={12} className='initial-count'>
                                                    <h6>Total Products : <span className='count'>{productsData.length}</span></h6>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={12} className='secondary-count'>
                                                    <h6>Active Products : <span className='count'>{productsData.length}</span></h6>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <i class="fa-solid fa-store float-end f-28 orange-clr"></i>
                                    </Col>
                                </Row>
                                <Button type="button" variant="outline-success" className="f-14 mt-2" onClick={() => handleNaviagekFunc("products")} >
                                    View Products
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default Dashboard;
