import React from 'react';
import PNF from '../../assets/pageNotFound.png';
import { Image } from 'react-bootstrap';


function PageNotFound() {
    return (
        <div>
            <Image src={PNF} className='img-fluid' style={{ display: "block", margin: "auto" }} />
        </div>
    );
}

export default PageNotFound;
