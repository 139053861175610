import React from 'react';
import { Container } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { MaterialReactTable } from 'material-react-table';
import { Paper } from '@mui/material';


function Orders() {
    

    const { orderData, loading, error } = useData();

    console.log("orderData",orderData);
    

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!orderData) {
        return <div>No user data available.</div>;
    }

    const columns = [
        {
            accessorKey: 'id',
            header: 'ID',
        },
        {
            accessorKey: 'id',
            header: 'Order Product',
        },
        {
            accessorKey: 'id',
            header: 'Quantiy',
        },
        {
            accessorKey: 'id',
            header: 'Ordered Amount',
        },
        {
            accessorKey: 'id',
            header: 'Ordered Date',
        }
    ];



    return (
        <Container>
            <p className='main-heading-pages'>Orders</p>
            <Paper style={{ height: '450px', overflowY: 'auto', padding: 20 }}>
                <MaterialReactTable
                    columns={columns}
                    data={orderData}
                    enableSorting
                    enablePagination
                />
            </Paper>
        </Container>
    );
}

export default Orders;
