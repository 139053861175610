import React, { useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import Loading from '../Loading/Loading';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';


function Subscriptions() {

    const { subscribersData, loading, error, todaySubscriptions } = useData();
    const [activeButton, setActiveButton] = useState('total');


    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!subscribersData) {
        return <div>No user data available.</div>;
    }

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'inserted_time',
            header: 'Subscribed Date',
            Cell: ({ cell }) => {
                const formatDateTimeToUS = (dateString) => {
                    const date = new Date(dateString);
                    return date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }).replace(',', '');
                };

                return <span>{formatDateTimeToUS(cell.getValue() || '')}</span>;
            },
        }

    ];



    return (
        <Container>
            <p className='main-heading-pages text-uppercase'>Subscribed Users</p>
            <Paper style={{ height: '450px', overflowY: 'auto', padding: 20 }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 ${activeButton === 'total' ? 'active' : ''}`}  // Toggle active class
                                onClick={() => setActiveButton('total')}  // Set active button
                            >
                                Total Subscribed - <span >{subscribersData?.length || 0}</span>
                            </Button>
                        </Col>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 ${activeButton === 'today' ? 'active' : ''}`}  // Toggle active class
                                onClick={() => setActiveButton('today')}  // Set active button
                            >
                                Today Subscribed - <span>{todaySubscriptions?.length || 0}</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={activeButton === 'total' ? subscribersData : todaySubscriptions}
                />
            </Paper>
        </Container>
    );
}

export default Subscriptions;
