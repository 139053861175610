import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './SideNavbar.css';

function SideNavbar() {
    const location = useLocation();
    const isCategoriesActive = location.pathname.startsWith('/categories') || location.pathname === '/add-categories';
    const isArtistsActive = location.pathname.startsWith('/artists') || location.pathname === '/add-artist';
    const isProductActive = location.pathname.startsWith('/products') || location.pathname === '/add-product';

    return (
        <>
            <div>
                <img
                    src='https://artfrocollection.com/images/logo.png'
                    className='w-100'
                    alt='Main Logo'
                />
            </div>
            <ul className='sidebar-nav'>
                <li className="sidebar-item">
                    <NavLink
                        to="/dashboard"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="Dashboard"
                    >
                        <i className="fa-solid fa-house snb-icon" aria-hidden="true"></i>
                        <span>Dashboard</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/registered-users"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="Registered Users"
                    >
                        <i className="fa-regular fa-user snb-icon" aria-hidden="true"></i>
                        <span>Registered Users</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/categories"
                        className={`sidebar-link ${isCategoriesActive ? 'active' : ''}`}
                        aria-label="Categories"
                    >
                        <i className="fa-solid fa-list" aria-hidden="true"></i>
                        <span>Categories</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/artists"
                        className={`sidebar-link ${isArtistsActive ? 'active' : ''}`}
                        aria-label="Artists"
                    >
                        <i className="fa-solid fa-paintbrush" aria-hidden="true"></i>
                        <span>Artists</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/products"
                        className={`sidebar-link ${isProductActive ? 'active' : ''}`}
                        aria-label="products"
                    >
                        <i className="fa-solid fa-store" aria-hidden="true"></i>
                        <span>Products</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/orders"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="orders"
                    >
                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                        <span>Orders</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/subscriptions"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="subscriptions"
                    >
                        <i class="fa-solid fa-bell"></i>
                        <span>Subscriptions</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/donations"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="donations"
                    >
                        <i class="fa-solid fa-hand-holding-dollar" aria-hidden="true"></i>
                        <span>Donations</span>
                    </NavLink>
                </li>
                <li className="sidebar-item">
                    <NavLink
                        to="/contact-support"
                        className="sidebar-link"
                        activeclassname="active"
                        aria-label="contact"
                    >
                        <i class="fa-solid fa-headset" aria-hidden="true"></i>
                        <span>Contact Support</span>
                    </NavLink>
                </li>
            </ul>
        </>
    );
}

export default SideNavbar;
