import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import AddCategoriesModel from './AddCategoriesModel';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import EditCategoriesModel from './EditCategoriesModel';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';
import Loading from '../Loading/Loading';
import { fetchFromApi } from '../../config/api';
import API_ENDPOINTS from '../../config/apiConfig';

function Categories() {
    const { categoriesData, loading, error, fetchCategoriesData } = useData();
    const [modalShow, setModalShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [deleteLoading, setDeleteLoading] = useState(false); // State for loading during deletion
    const [deleteError, setDeleteError] = useState(null); // State for delete error

    useEffect(() => {
        if (!categoriesData) {
            fetchCategoriesData();
        }
    }, [fetchCategoriesData, categoriesData]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!categoriesData) {
        return <div>No categories data available.</div>;
    }

    const formatDateTimeToUS = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date.getTime()) ? "-" : date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        }).replace(',', '');
    };

    const activeEditModal = (row) => {
        setEditData(row.original);
        setEditModal(true);
    };

    const deleteRow = async (row) => {
        if (window.confirm("Are you sure you want to delete category?")) {
            setDeleteLoading(true); // Set loading to true while deleting
            try {
                const formBody = {
                    id: row.original.id,
                };
                const data = await fetchFromApi(API_ENDPOINTS.CATEGORIES_DELETING, 'POST', formBody);

                if (data.status === "success") {
                    await fetchCategoriesData();
                }
            } catch (error) {
                console.error(error);
                setDeleteError("Failed to delete category. Please try again."); // Set error message
            } finally {
                setDeleteLoading(false); // Reset loading state
            }

        }
    };

    const renderRowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
                <IconButton onClick={() => activeEditModal(row)}>
                    <ModeEditOutlineSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton onClick={() => deleteRow(row)} disabled={deleteLoading}>
                    <DeleteIcon className='text-danger' />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'category_name',
            header: 'Category Name',
        },
        {
            accessorKey: 'inserted_time',
            header: 'Inserted Date',
            Cell: ({ cell }) => <span>{formatDateTimeToUS(cell.getValue() || '')}</span>,
        },
        {
            accessorKey: 'updated_time',
            header: 'Updated Date',
            Cell: ({ cell }) => <span>{formatDateTimeToUS(cell.getValue() || '')}</span>,
        },
        {
            id: 'actions',
            header: 'Actions',
            Cell: renderRowActions,
            size: 100,
            muiTableBodyCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
        }
    ];

    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='main-heading-pages text-uppercase'>Categories</p>
                </div>
                <div className='p-2'>
                    <Button type="button" variant="outline-success" className="f-14" onClick={() => setModalShow(true)}>
                    <i class="fa-solid fa-plus"></i> Add Category
                    </Button>
                </div>
            </div>

            <Paper style={{ height: '450px', overflowY: 'auto', padding: 20 }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 active`}  // Toggle active class
                            >
                                Total Categories - <span >{categoriesData?.length || 0}</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={categoriesData}
                />
            </Paper>
            {
                modalShow && (
                    <AddCategoriesModel
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                )
            }
            {
                editModal && (
                    <EditCategoriesModel
                        show={editModal}
                        editData={editData}
                        onHide={() => {
                            setEditModal(false);
                            setEditData({});
                        }}
                    />
                )
            }
            {deleteError && <div className="text-danger">{deleteError}</div>} {/* Show delete error message */}
        </Container>
    );
}

export default Categories;
