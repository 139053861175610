import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import API_ENDPOINTS from '../config/apiConfig';
import { fetchFromApi } from '../config/api';
import Cookies from 'js-cookie';

const DataContext = createContext();

export const DataProvider = ({ children }) => {
    const [data, setData] = useState({
        userData: [],
        orderData: [],
        donationsData: [],
        categoriesData: [],
        subscribersData: [],
        contactedData: [],
        artistData: [],
        productsData: [],
        todayRegistrations: [],
        totalDonationsAmount: 0,
        todaySubscriptions: [],
        todayContacted: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchFromApiWithToken = useCallback(async (endpoint, setter) => {
        try {
            const response = await fetchFromApi(endpoint, 'GET');
            if (response.status === 'success') {
                setter(response.data);
            } else {
                throw new Error(`Failed to fetch data from ${endpoint}`);
            }
        } catch (err) {
            console.error(err);
            setError(err.message);
        }
    }, []);

    const checkTodayRegistrations = useCallback(() => {
        const today = new Date().toISOString().split('T')[0];
        const registrationsToday = data.userData.filter(user => user.inserted_time.split(' ')[0] === today);

        setData(prev => ({
            ...prev,
            todayRegistrations: registrationsToday,
        }));
    }, [data.userData]);

    const checkDonationsAmount = useCallback(() => {
        const donationsAmount = data.donationsData.reduce((acc, donation) => acc + donation.amount, 0);
        setData(prev => ({
            ...prev,
            totalDonationsAmount: donationsAmount,
        }));
    }, [data.donationsData]);

    const checkTodaySubscriptions = useCallback(() => {
        const today = new Date().toISOString().split('T')[0];
        const subscriptionsToday = data.subscribersData.filter(user => user.inserted_time.split(' ')[0] === today);

        setData(prev => ({
            ...prev,
            todaySubscriptions: subscriptionsToday,
        }));
    }, [data.subscribersData]);

    const checkTodayContacted = useCallback(() => {
        const today = new Date().toISOString().split('T')[0];
        const contactedToday = data.contactedData.filter(user => user.inserted_time.split(' ')[0] === today);

        setData(prev => ({
            ...prev,
            todayContacted: contactedToday,
        }));
    }, [data.contactedData]);

    const fetchUserData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.REG_USERS, data => setData(prev => ({ ...prev, userData: data }))), [fetchFromApiWithToken]);
    const fetchOrderData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.ORDERS, data => setData(prev => ({ ...prev, orderData: data }))), [fetchFromApiWithToken]);
    const fetchDonationsData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.DONATIONS, data => setData(prev => ({ ...prev, donationsData: data }))), [fetchFromApiWithToken]);
    const fetchCategoriesData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.PRODUCT_CATEGORIES, data => setData(prev => ({ ...prev, categoriesData: data }))), [fetchFromApiWithToken]);
    const fetchSubscribersData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.SUBSCRIBERS, data => setData(prev => ({ ...prev, subscribersData: data }))), [fetchFromApiWithToken]);
    const fetchContactedData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.ENQURIES, data => setData(prev => ({ ...prev, contactedData: data }))), [fetchFromApiWithToken]);
    const fetchArtistData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.ARTISTS, data => setData(prev => ({ ...prev, artistData: data }))), [fetchFromApiWithToken]);
    const fetchProductsData = useCallback(() => fetchFromApiWithToken(API_ENDPOINTS.PRODUCTS, data => setData(prev => ({ ...prev, productsData: data }))), [fetchFromApiWithToken]);


    const fetchDataAfterLogin = useCallback(async () => {
        const token = Cookies.get('artfroAdminToken');

        console.log("token", token);

        setLoading(true);
        if (!token) {
            setLoading(false);
            return;
        }

        try {
            await Promise.all([
                fetchUserData(),
                fetchOrderData(),
                fetchDonationsData(),
                fetchCategoriesData(),
                fetchSubscribersData(),
                fetchContactedData(),
                fetchArtistData(),
                fetchProductsData(),
            ]);
        } catch (err) {
            console.error(err);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    }, [
        fetchUserData,
        fetchOrderData,
        fetchDonationsData,
        fetchCategoriesData,
        fetchSubscribersData,
        fetchContactedData,
        fetchArtistData,
        fetchProductsData,
    ]);

    useEffect(() => {
        fetchDataAfterLogin();
    }, [fetchDataAfterLogin]);

    useEffect(() => {
        if (data.userData.length) checkTodayRegistrations();
    }, [data.userData, checkTodayRegistrations]);

    useEffect(() => {
        if (data.donationsData.length) checkDonationsAmount();
    }, [data.donationsData, checkDonationsAmount]);

    useEffect(() => {
        if (data.contactedData.length) checkTodayContacted();
    }, [data.contactedData, checkTodayContacted]);

    useEffect(() => {
        if (data.subscribersData.length) checkTodaySubscriptions();
    }, [data.subscribersData, checkTodaySubscriptions]);

    const contextValue = useMemo(() => ({
        ...data,
        loading,
        error,
        fetchUserData,
        fetchOrderData,
        fetchDonationsData,
        fetchCategoriesData,
        fetchSubscribersData,
        fetchContactedData,
        fetchArtistData,
        fetchProductsData,
        fetchDataAfterLogin
    }), [data, loading, error, fetchUserData, fetchOrderData, fetchDonationsData, fetchCategoriesData, fetchSubscribersData, fetchContactedData, fetchArtistData, fetchProductsData, fetchDataAfterLogin]);

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
