import React from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

const MaterialReactTableWithSearch = ({ columns, data, enableRowActions, renderRowActions, name, enableRowSelection, renderTopToolbarCustomActions, enableSelectAll }) => {


  const table = useMaterialReactTable({
    columns,
    data,
    enableSelectAll,
    renderTopToolbarCustomActions,
    enableRowSelection,
    enableStickyHeader: false,
    enableColumnFilterModes: false,
    enableColumnOrdering: false,
    enableGrouping: false,
    enableColumnPinning: false,
    enableFacetedValues: false,
    enableColumnFilters: false,
    enableHiding: false,
    enableColumnActions: false,
    enableRowActions,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableGlobalFilter: true,
    muiTableContainerProps: { sx: { maxHeight: 'auto' } },
    initialState: {
      showColumnFilters: false,
      showGlobalFilter: false,
      columnPinning: {
        left: ['mrt-row-expand', 'mrt-row-select'],
        right: ['mrt-row-actions'],
      },
    },
    renderRowActions,
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    muiSearchTextFieldProps: {
      size: 'small',
      variant: 'outlined',
    },
    muiPaginationProps: {
      color: 'secondary',
      rowsPerPageOptions: [10, 50, 100, 200],
      shape: 'rounded',
      variant: 'outlined',
    },
  });


  return (
    <div >
      <MaterialReactTable table={table} />
    </div>
  );
};

export default MaterialReactTableWithSearch;
