import React, { useState } from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import Loading from '../Loading/Loading';
import './RegisteredUsers.css';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';

function RegisteredUsers() {

    const { userData, loading, error,todayRegistrations } = useData();
    const [activeButton, setActiveButton] = useState('total'); 

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!userData) {
        return <div>No user data available.</div>;
    }

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'name',
            header: 'Name',
        },
        {
            accessorKey: 'email',
            header: 'Email',
        },
        {
            accessorKey: 'phone',
            header: 'Mobile',
            Cell: ({ cell }) => {
                const formatPhoneNumber = (number) =>
                    number ? number.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';

                return <span>{formatPhoneNumber(cell.getValue())}</span>;
            },
        },
        {
            accessorKey: 'inserted_time',
            header: 'Registered Date',
            Cell: ({ cell }) => {
                const formatDateTimeToUS = (dateString) => {
                    const date = new Date(dateString);
                    return date.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true,
                    }).replace(',', ''); // Remove comma for cleaner format
                };

                return <span>{formatDateTimeToUS(cell.getValue() || '')}</span>;
            },
        }
    ];

    return (
        <Container>
            <p className='main-heading-pages text-uppercase'>Registered Users</p>
            <Paper style={{ height: '450px', overflowY: 'auto', padding: 20 }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 ${activeButton === 'total' ? 'active' : ''}`}  // Toggle active class
                                onClick={() => setActiveButton('total')}  // Set active button
                            >
                                Total Registered - <span >{userData?.length || 0}</span>
                            </Button>
                        </Col>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 ${activeButton === 'today' ? 'active' : ''}`}  // Toggle active class
                                onClick={() => setActiveButton('today')}  // Set active button
                            >
                                Today Registered - <span>{todayRegistrations?.length || 0}</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={activeButton === 'total' ? userData : todayRegistrations}
                />
            </Paper>
        </Container>
    );
}

export default RegisteredUsers;
