import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { Form, Row, Col, Button, Image } from 'react-bootstrap';
import API_ENDPOINTS from '../../config/apiConfig';
import { useData } from '../../context/DataContext';
import { fetchFromApi } from '../../config/api';

function AddArtistModel(props) {

    const { fetchArtistData } = useData();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [artistData, setArtistData] = useState({
        name: '',
        email: '',
        mobile: '',
        address: '',
        about: '',
        image: ''
    });

    // Handle input change for text fields
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setArtistData({
            ...artistData,
            [name]: value,
        });
        setError("")
    };

    // Handle image change (from URL or file input)
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setArtistData({
                    ...artistData,
                    image: reader.result, // base64 encoded image string
                });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation for required fields and email pattern
        if (!artistData.name || !artistData.email || !artistData.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
            setError("Please fill all required fields with valid information.");
            return;
        }

        // Additional validation for mobile number (should be 10 digits)
        if (artistData.mobile && artistData.mobile.length !== 10) {
            setError("Please enter a valid 10-digit mobile number.");
            return;
        }

        setError("");
        setLoading(true);

        
        try {
            const formBody = {
                name: artistData.name,
                email: artistData.email,
                address: artistData.address,
                mobile: artistData.mobile,
                about: artistData.about,
                image: artistData.image
            };

            const data = await fetchFromApi(API_ENDPOINTS.ADD_ARTIST, 'POST', formBody);

            if (data.status === "success") {
                setSuccessMsg("Artist Data Updated Successfully");

                setTimeout(async () => {
                    setSuccessMsg("");
                    props.onHide();
                    await fetchArtistData();
                }, 2000);
            } else {
                setError("Failed to update artist. Please check the details and try again.");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Artist
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistName">
                                <Form.Label>Artist Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={artistData.name}
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={error && !artistData.name}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter the artist's name.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistEmail">
                                <Form.Label>Artist Email <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={artistData.email}
                                    onChange={handleInputChange}
                                    required
                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                    isInvalid={error && (!artistData.email || !artistData.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/))}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid email address.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistMobile">
                                <Form.Label>Artist Mobile</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="mobile"
                                    value={artistData.mobile}
                                    onChange={handleInputChange}
                                    pattern="\d{10}"
                                    maxLength={10}
                                    onKeyPress={(e) => {
                                        if (!/^\d$/.test(e.key)) {
                                            e.preventDefault();
                                        }
                                    }}
                                    isInvalid={error && artistData.mobile && artistData.mobile.length !== 10}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please enter a valid 10-digit mobile number.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistAddress">
                                <Form.Label>Artist Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={artistData.address}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistAbout">
                                <Form.Label>About Artist</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="about"
                                    value={artistData.about}
                                    onChange={handleInputChange}
                                    rows={8}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                            <Form.Group controlId="formArtistImage">
                                <Form.Label>Artist Image</Form.Label>
                                <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                {artistData.image && (
                                    <Image
                                        src={artistData.image}
                                        fluid
                                        className="mt-3"
                                        style={{ width: "150px", height: "150px" }}
                                    />
                                )}
                            </Form.Group>
                        </Col>
                    </Row>

                    {error && <div className="text-danger">{error}</div>}
                    {successMsg && <div className="text-success">{successMsg}</div>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? "Adding..." : "Add"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddArtistModel;
