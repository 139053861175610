import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { fetchFromApi } from '../../config/api';
import API_ENDPOINTS from '../../config/apiConfig';
import { useData } from '../../context/DataContext';


function AddCategoriesModel(props) {
    const { fetchCategoriesData } = useData();
    const [category, setCategory] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const editCategory = (e) => {
        setCategory(e.target.value);
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!category) {
            setError("Category name cannot be empty");
        } else {
            setLoading(true);
            setError("");
            try {
                const formBody = {
                    category_name: category
                };
                const data = await fetchFromApi(API_ENDPOINTS.CATEGORIES_ADDING, 'POST', formBody);

                if (data.status === "success") {
                    setSuccessMsg("Category Added Successfully");
                    setTimeout(async () => {
                        setCategory("");
                        setSuccessMsg("");
                        props.onHide();
                        await fetchCategoriesData();
                    }, 2000);
                } else {
                    setError("Failed to add category");
                }
            } catch (error) {
                setError("An error occurred. Please try again.");
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Category Name"
                            value={category}
                            onChange={editCategory}
                            isInvalid={!!error}
                            disabled={loading}
                        />
                        {error && (
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                    {successMsg && <div className="text-success">{successMsg}</div>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="button"
                    variant="outline-success"
                    className="f-14"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? "Adding ..." : "Add"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AddCategoriesModel;
