import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { fetchFromApi } from '../../config/api';
import API_ENDPOINTS from '../../config/apiConfig';
import { useData } from '../../context/DataContext';

function EditCategoriesModel(props) {
    const { fetchCategoriesData } = useData();

    const [category, setCategory] = useState(props.editData.category_name || ""); // Fallback for undefined
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    const editCategory = (e) => {
        setCategory(e.target.value);
        setError("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!category.trim()) {
            setError("Category name cannot be empty");
            return;
        }
        setLoading(true);
        setError("");
        
        try {
            const formBody = {
                id: props.editData.id,
                category_name: category.trim(), // trim to remove unnecessary spaces
            };
            const data = await fetchFromApi(API_ENDPOINTS.CATEGORIES_UPDATE, 'POST', formBody);

            if (data.status === "success") {
                setSuccessMsg("Category Updated Successfully");

                setTimeout(async () => {
                    setCategory("");
                    setSuccessMsg("");
                    props.onHide(); // Close modal
                    await fetchCategoriesData(); // Refresh categories data
                }, 2000);
            } else {
                setError(data.message || "Failed to update category");
            }
        } catch (error) {
            setError("An error occurred. Please try again.");
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Edit Category
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label>Enter Category Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Category Name"
                            value={category}
                            onChange={editCategory}
                            isInvalid={!!error}
                            disabled={loading} // Disable input while loading
                        />
                        <Form.Control.Feedback type="invalid">
                            {error}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {successMsg && <div className="text-success">{successMsg}</div>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    type="submit" // Change to submit button
                    variant="outline-success"
                    className="f-14"
                    disabled={loading} 
                    onClick={handleSubmit}
                >
                    {loading ? "Updating ..." : "Update"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditCategoriesModel;
