import { useState, useEffect } from "react";
import { Row, Col, Image, Button, Form, Alert } from 'react-bootstrap';
import './Login.css';
import logo from '../../assets/Artfro-Logo1.png';
import { useNavigate } from 'react-router-dom';
import API_ENDPOINTS from '../../config/apiConfig';
import { fetchFromApi } from '../../config/api';
import { useAuth } from '../../context/AuthContext';
import { useData } from '../../context/DataContext';

function Login() {
    const [userDetail, setUserDetail] = useState({ email: "", password: "" });
    const [emailError, setEmailError] = useState(null);
    const [passwordError, setPasswordError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(null);

    const { isAuthenticated, login } = useAuth();
    const { fetchDataAfterLogin } = useData();
    const navigate = useNavigate();

    const onValueChange = (e) => {
        setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
        if (e.target.name === "email") setEmailError(null);
        if (e.target.name === "password") setPasswordError(null);
        setApiError(null);
    };

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };


    useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        let valid = true;
        if (!isValidEmail(userDetail.email)) {
            setEmailError("Please enter a valid email address.");
            valid = false;
        }
        if (userDetail.password.length === 0) {
            setPasswordError("Please enter a valid password.");
            valid = false;
        }
        if (!valid) return;

        setLoading(true);
        
        try {
            const formBody = {
                email: userDetail.email,
                password: userDetail.password
            };
            const data = await fetchFromApi(API_ENDPOINTS.LOGIN, 'POST', formBody);

            if (data.status === "success") {
                login(data.token, JSON.stringify(data.user));
                await fetchDataAfterLogin();
                navigate('/dashboard');
            } else {
                if (data.errorType === "email") {
                    setEmailError(data.message);
                } else {
                    setPasswordError(data.message);
                }
                setApiError(data.message); // Set API error
            }
        } catch (err) {
            setApiError("An error occurred. Please try again."); // General error message
        } finally {
            setLoading(false);
        }
    };

    return (
        <Row>
            <Col sm={6} className="d-flex justify-content-center align-items-center login-left">
                <Image src={logo} className='img-fluid' />
            </Col>
            <Col sm={6} className='d-flex justify-content-center align-items-center login-right'>
                <div>
                    <div className="heading">
                        <h1 className="heading-content text-center">Login</h1>
                    </div>
                    {apiError && <Alert variant="danger">{apiError}</Alert>} {/* Display API error */}
                    <div className="login-form">
                        <Form onSubmit={handleLoginSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    value={userDetail.email}
                                    type="email"
                                    onChange={onValueChange}
                                    name="email"
                                    placeholder="Enter email"
                                    isInvalid={!!emailError}
                                />
                                {emailError && (
                                    <Form.Control.Feedback type="invalid">
                                        {emailError}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword" className="mt-2">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    value={userDetail.password}
                                    type="password"
                                    name="password"
                                    onChange={onValueChange}
                                    placeholder="Password"
                                    isInvalid={!!passwordError}
                                />
                                {passwordError && (
                                    <Form.Control.Feedback type="invalid">
                                        {passwordError}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                className="submit-btn mt-2"
                                disabled={loading}
                            >
                                {loading ? "Logging in..." : "Submit"}
                            </Button>
                        </Form>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

export default Login;
