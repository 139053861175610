import React, { useState } from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import { useData } from '../../context/DataContext';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton, Tooltip } from '@mui/material';
import ModeEditOutlineSharpIcon from '@mui/icons-material/ModeEditOutlineSharp';
import EditArtistModel from './EditArtistModel';
import AddArtistModel from './AddArtistModel';
import Loading from '../Loading/Loading';
import MaterialReactTableWithSearch from '../MaterialTable/MaterialReactTableWithSearch';
import { fetchFromApi } from '../../config/api';
import API_ENDPOINTS from '../../config/apiConfig';

function Artists() {
    const { artistData, loading, error, refetch, fetchArtistData } = useData();
    const [editModal, setEditModal] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [deleteLoading, setDeleteLoading] = useState(false); // State for loading during deletion
    const [deleteError, setDeleteError] = useState(null); // State for delete error

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
                <Button onClick={refetch}>Retry</Button>
            </div>
        );
    }

    if (!artistData || artistData.length === 0) {
        return <div>No artist data available.</div>;
    }

    const activeEditModal = (row) => {
        setEditData(row.original);
        setEditModal(true);
    };

    const deleteRow = async (row) => {
        if (window.confirm("Are you sure you want to delete this artist?")) {
            setDeleteLoading(true); // Set loading to true while deleting
            try {
                const formBody = {
                    id: row.original.id,
                };

                const data = await fetchFromApi(API_ENDPOINTS.DELETE_ARTIST, 'POST', formBody);
                console.log("dataa", data);

                if (data.status === "success") {
                    await fetchArtistData();
                }
            } catch (error) {
                console.error(error);
                setDeleteError("Failed to delete category. Please try again."); // Set error message
            } finally {
                setDeleteLoading(false); // Reset loading state
            }
        }
    };

    const renderRowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end' }}>
            <Tooltip title="Edit">
                <IconButton onClick={() => activeEditModal(row)}>
                    <ModeEditOutlineSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <IconButton onClick={() => deleteRow(row)} disabled={deleteLoading}>
                    <DeleteIcon className='text-danger' />
                </IconButton>
            </Tooltip>
        </Box>
    );

    const columns = [
        {
            accessorFn: (row, index) => index + 1,
            header: 'SNO',
            id: 'id',
        },
        {
            accessorKey: 'image',
            header: 'Image',
            accessorFn: (row) => row.image || '-', // Return "-" if image URL is not present
            Cell: ({ cell }) => {
                const imageUrl = cell.getValue();
                return (
                    <div style={{ textAlign: 'center' }}>
                        {imageUrl === '-' ? (
                            <span>-</span> // Show "-" when no image is available
                        ) : (
                            <img
                                src={imageUrl}
                                alt="Artist"
                                style={{ width: '100px', height: '100px', borderRadius: '5px' }}
                            />
                        )}
                    </div>
                );
            },
        },

        {
            accessorKey: 'name',
            header: 'Name',
            accessorFn: (row) => row.name || "-",
        },
        {
            accessorKey: 'email',
            header: 'Email',
            accessorFn: (row) => row.email || "-",
        },
        {
            accessorKey: 'mobile',
            header: 'Mobile',
            accessorFn: (row) => {
                const number = row.mobile;
                const formatPhoneNumber = (num) =>
                    num ? num.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : '';
                return number !== "" ? formatPhoneNumber(number) : "-";
            },
        },
        {
            accessorKey: 'address',
            header: 'Address',
            accessorFn: (row) => row.address || "-",
        },
        {
            accessorKey: 'about',
            header: 'About Artist',
            accessorFn: (row) => row.about || "-",
        },
        {
            accessorKey: 'inserted_time',
            header: 'Added Date',
            accessorFn: (row) => row.inserted_time || "-",
        },
        {
            accessorKey: 'updated_time',
            header: 'Updated Date',
            accessorFn: (row) => row.updated_time || "-",
        },
        {
            id: 'actions',
            header: 'Actions',
            Cell: renderRowActions,
            size: 100,
            muiTableBodyCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
            muiTableHeadCellProps: {
                sx: {
                    position: 'sticky',
                    right: 0,
                    backgroundColor: '#fff',
                    zIndex: 1,
                },
            },
        }
    ];

    return (
        <Container>
            <div className='d-flex justify-content-between'>
                <div>
                    <p className='main-heading-pages'>Artists</p>
                </div>
                <div className='p-2'>
                    <Button type="button" variant="outline-success" className="f-14" onClick={() => setAddModal(true)}>
                        <i className="fa-solid fa-plus"></i> Add Artist
                    </Button>
                </div>
            </div>
            <Paper style={{ padding: 20, height: '400px', overflowX: 'auto' }}>
                <div className='mb-3'>
                    <Row>
                        <Col sm={2}>
                            <Button
                                type="button"
                                variant="outline-warning"
                                className={`f-14 mt-2 active`}
                            >
                                Total Artists - <span>{artistData.length || 0}</span>
                            </Button>
                        </Col>
                    </Row>
                </div>
                <MaterialReactTableWithSearch
                    columns={columns}
                    data={artistData}
                />
            </Paper>
            {editModal && (
                <EditArtistModel
                    show={editModal}
                    editData={editData}
                    onHide={() => {
                        setEditModal(false);
                        setEditData({});
                    }}
                />
            )}
            {addModal && (
                <AddArtistModel
                    show={addModal}
                    onHide={() => setAddModal(false)}
                />
            )}

            {deleteError && <div className="text-danger">{deleteError}</div>}
        </Container>
    );
}

export default Artists;
